import React from 'react'

export function Header() {
  return (
    <>
      <h1>ConHex</h1>
      <h3>Connection game by Michail Antonow</h3>
    </>
  )
}
